import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HELPER } from "../services";
import { ROUTES } from "../configs";
import { useSelector } from "react-redux";

const Navdata = () => {
  const history = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const auth = useSelector((state) => state?.auth?.user);

  const [menus, setMenus] = useState([
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Dashboard",
      icon: "ri-dashboard-2-line",
      link: ROUTES.DASHBOARD,
      // role: "customerRole",
      isActive: false,
      click: function (e) {
        e.preventDefault();
        changeMenu("dashboard");
      },
    },
    {
      id: "admin",
      label: "User Management",
      icon: " ri-user-2-line",
      link: ROUTES.USERS,
      parentId: "apps",
      isActive: false,
      permission: "users",
      click: function (e) {
        e.preventDefault();
        changeMenu("admin");
      },
    },

    {
      id: "customers",
      label: "Customer",
      icon: "ri-account-circle-line",
      link: ROUTES.CUSTOMER,
      permission: "customer",
      // role: "customerRole",
      click: function (e) {
        e.preventDefault();
        changeMenu("customers");
      },
    },
    {
      id: "apps_ecommerce_698",
      label: "Categories Management",
      icon: " ri-order-play-fill",
      link: "/#",
      isActive: false,
      isChildItem: true,
      click: function (e) {
        e.preventDefault();
        changeMenu("apps_ecommerce_698");
      },
      subItems: [
        {
          id: "apps_ecommerce_11",
          label: "Category",
          link: ROUTES.CATEGORY,
          parentId: "apps",
          isActive: false,
          permission: "category",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_11");
          },
        },
        {
          id: "apps_ecommerce_19",
          label: "Sub Category",
          link: ROUTES.SUB_CATEGORY,
          parentId: "apps",
          isActive: false,
          permission: "sub_category",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_19");
          },
        },
      ],
    },

    {
      id: "apps_ecommerce_677",
      label: "Attributes & Options",
      icon: "ri-apps-2-line",
      link: "/#",
      isActive: false,
      isChildItem: true,
      click: function (e) {
        e.preventDefault();
        changeMenu("apps_ecommerce_677");
      },
      subItems: [
        {
          id: "apps_ecommerce_45",
          label: "Attributes",
          link: ROUTES.ATTRIBUTE,
          parentId: "apps",
          isActive: false,
          permission: "attributes",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_45");
          },
        },
        {
          id: "apps_ecommerce_89",
          label: "Option",
          link: ROUTES.OPTIONS,
          parentId: "apps",
          isActive: false,
          permission: "options",
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_89");
          },
        },
      ],
    },
    {
      id: "apps_ecommerce_26",
      label: "Catalog Managment",
      icon: "ri-copyright-line",
      link: ROUTES.CATALOG_MASTER,
      parentId: "apps",
      isActive: false,
      permission: "catalog_master",
      click: function (e) {
        e.preventDefault();
        changeMenu("apps_ecommerce_26");
      },
    },
    {
      id: "apps_ecommerce_150",
      label: "Products",
      icon: "ri-product-hunt-line",
      link: ROUTES.PRODUCT,
      parentId: "apps",
      isActive: false,
      permission: "product",
      click: function (e) {
        e.preventDefault();
        changeMenu("apps_ecommerce_150");
      },
    },

    {
      id: "apps_ecommerce_874",
      label: "Quotation",
      link: ROUTES.ADMIN_QUOTATION,
      icon: "ri-file-list-2-line",
      parentId: "apps",
      isActive: false,
      permission: "quotation",
      click: function (e) {
        e.preventDefault();
        changeMenu("apps_ecommerce_874");
      },
    },
  ]);
  const changeMenu = (menuId, field = "id", __menus = null) => {
    let _menus = [...(__menus == null ? menus : __menus)];

    _menus = _menus.map((menu) => {
      menu.isActive = menu[field] == menuId ? true : false;

      if (menu.hasOwnProperty("subItems")) {
        let isMainMenuActive = false;

        menu.subItems = menu.subItems.map((subItem) => {
          let isSubMenuActive = false;
          const spitPath = menuId.split("/");
          const slicePath = spitPath.slice(0, 2);
          const id = slicePath.join("/");

          if (subItem[field] == id) {
            isMainMenuActive = true;
          }
          subItem.isActive = subItem[field] === id ? true : false;

          if (subItem.hasOwnProperty("childItems")) {
            subItem.childItems = subItem.childItems.map((childItem) => {
              childItem.isActive =
                childItem[field] == menuId ? !childItem.isActive : false;
              if (childItem[field] == menuId) {
                isMainMenuActive = true;
                isSubMenuActive = true;
              }

              return childItem;
            });
          }

          if (isSubMenuActive) {
            subItem.isActive = isSubMenuActive;
          }

          return subItem;
        });

        if (isMainMenuActive) {
          menu.isActive = isMainMenuActive;
        }
        return menu;
      }

      return menu;
    });

    if (field == "id") {
      _menus = activateCurrentRoute(window.location.pathname, _menus);
    }

    setMenus([..._menus]);
  };

  const activateCurrentRoute = (menuId, ___menus) => {
    let _menus = [...___menus];

    _menus = _menus.map((menu) => {
      menu.isActive = menu["link"] == menuId ? true : menu.isActive;
      if (menu.hasOwnProperty("subItems")) {
        menu.subItems = menu.subItems.map((subItem) => {
          subItem.isActive =
            subItem["link"] == menuId ? true : subItem.isActive;

          if (subItem.hasOwnProperty("childItems")) {
            subItem.childItems = subItem.childItems.map((childItem) => {
              childItem.isActive =
                childItem["link"] == menuId ? true : childItem.isActive;
              return childItem;
            });
          }

          return subItem;
        });

        return menu;
      }

      return menu;
    });

    return _menus;
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const initMenu = () => {
      changeMenu(window.location.pathname, "link");
    };

    if (!HELPER.isEmpty(menus)) {
      initMenu();
    }
  }, [path]);

  const hasPermission = (permissionName) => {
    return auth?.permissions?.some(
      (permission) =>
        permission.name === permissionName && permission.view === 1
    );
  };

  const filterMenus = (menus) => {
    return menus
      .map((menu) => {
        if (HELPER.isEmpty(auth?.name)) {
          if (menu.role !== "adminRole") {
            return null;
          }

          if (menu.subItems) {
            menu.subItems = menu.subItems.filter((subItem) => {
              return subItem.role !== "adminRole";
            });

            if (menu.subItems.length === 0) {
              return null;
            }
          }
        } else if (auth?.name === "Admin" && menu.role === "customerRole") {
          return null;
        } else {
          if (menu.permission && !hasPermission(menu.permission)) {
            return null;
          }

          if (menu.subItems) {
            menu.subItems = menu.subItems.filter((subItem) => {
              return !subItem.permission || hasPermission(subItem.permission);
            });

            if (menu.subItems.length === 0) {
              return null;
            }
          }
        }
        return menu;
      })
      .filter(Boolean);
  };

  const filteredMenus = filterMenus(menus);
  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
  }, [history]);

  return <React.Fragment>{filteredMenus}</React.Fragment>;
};
export default Navdata;
