import React, { useState } from "react";

export default function useQuotationFilter() {
  const [state, setState] = useState({
    productData: {},
    sequenceProductFilter: [
      {
        label: "Metal",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "metal",
        type: "dropdown",
      },
      {
        label: "Metal Color",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "metal_color",
        type: "dropdown",
      },
      {
        label: "Metal Purity",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "metal_purity",
        type: "dropdown",
      },
      {
        label: "Metal Weight",
        arrayAccessibleKey: "QuotationOtherDetails",
        key: "m950",
        type: "label",
      },
      {
        label: "Metal Weight",
        arrayAccessibleKey: "QuotationOtherDetails",
        key: "m18k",
        type: "label",
      },
      {
        label: "Metal Weight",
        arrayAccessibleKey: "QuotationOtherDetails",
        key: "m14k",
        type: "label",
      },
      {
        label: "Metal Weight",
        arrayAccessibleKey: "QuotationOtherDetails",
        key: "m10k",
        type: "label",
      },
      {
        label: "Metal Weight",
        arrayAccessibleKey: "QuotationOtherDetails",
        key: "m925",
        type: "label",
      },
      {
        label: "Ring Jewelry Size",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "rn_jewelry_size",
        type: "dropdown",
      },
      {
        label: "Earing Jewelry Size",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "er_pn_jewelry_size",
        type: "dropdown",
      },
      {
        label: "Necklace Jewelry Size",
        arrayAccessibleKey: "QuotationOtherDetails",
        key: "br_nk_jewelry_size",
        type: "textbox",
      },
      {
        label: "Length",
        arrayAccessibleKey: "QuotationOtherDetails",
        key: "length",
        type: "textbox",
      },
      {
        label: "Width",
        arrayAccessibleKey: "QuotationOtherDetails",
        key: "width",
        type: "textbox",
      },
      {
        label: "Thickness",
        arrayAccessibleKey: "QuotationOtherDetails",
        key: "thickness",
        type: "textbox",
      },

      // ----------------- Primary Diamond -----------------
      {
        topLabel: "Primary Diamond",
        label: "P Stone Type",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "p_stone_type",
        type: "dropdown",
      },
      {
        label: "P Stone Certificate",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "p_stone_certificate",
        type: "dropdown",
      },
      {
        label: "P Stone Name",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "p_stone_name",
        type: "dropdown",
      },
      {
        label: "P Shape",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "p_shape",
        type: "dropdown",
      },

      {
        label: "P MM Size",
        arrayAccessibleKey: "QuotationOtherDetails",
        key: "p_mm_size",
        type: "textbox",
      },

      {
        label: "P Piece",
        arrayAccessibleKey: "QuotationOtherDetails",
        key: "p_piece",
        type: "label",
      },
      {
        label: "P Color",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "p_color",
        type: "dropdown",
      },
      {
        label: "P Clarity",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "p_clarity",
        type: "dropdown",
      },
      {
        label: "P Carat",
        arrayAccessibleKey: "QuotationOtherDetails",
        key: "p_carat",
        type: "textbox",
      },
      // ----------------- Sd1 (Side Diamond 1)  -----------------
      {
        topLabel: "Side Diamond 1",
        label: "Sd1 Stone Type",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "sd1_stone_type",
        type: "dropdown",
      },
      {
        label: "Sd1 Stone Certificate",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "sd1_stone_certificate",
        type: "dropdown",
      },
      {
        label: "Sd1 Stone Name",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "sd1_stone_name",
        type: "dropdown",
      },
      {
        label: "Sd1 Shape",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "sd1_shape",
        type: "dropdown",
      },
      {
        label: "Sd1 Size",
        arrayAccessibleKey: "QuotationOtherDetails",
        key: "sd1_mm_size",
        type: "textbox",
      },
      {
        label: "Sd1 Piece",
        arrayAccessibleKey: "QuotationOtherDetails",
        key: "sd1_piece",
        type: "label",
      },
      {
        label: "Sd1 Color",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "sd1_color",
        type: "dropdown",
      },
      {
        label: "Sd1 Clarity",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "sd1_clarity",
        type: "dropdown",
      },
      {
        label: "Sd1 Carat",
        arrayAccessibleKey: "QuotationOtherDetails",
        key: "sd1_carat",
        type: "textbox",
      },
      //  ------------------- Sd2 (Side Diamond 2) ---------------
      {
        topLabel: "Side Diamond 2",
        label: "Sd2 Stone Type",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "sd2_stone_type",
        type: "dropdown",
      },
      {
        label: "Sd2 Stone Certificate",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "sd2_stone_certificate",
        type: "dropdown",
      },
      {
        label: "Sd2 Stone Name",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "sd2_stone_name",
        type: "dropdown",
      },
      {
        label: "Sd2 Shape",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "sd2_shape",
        type: "dropdown",
      },
      {
        label: "Sd2 Size",
        arrayAccessibleKey: "QuotationOtherDetails",
        key: "sd2_mm_size",
        type: "textbox",
      },
      {
        label: "Sd2 Piece",
        arrayAccessibleKey: "QuotationOtherDetails",
        key: "sd2_piece",
        type: "label",
      },
      {
        label: "Sd2 Color",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "sd2_color",
        type: "dropdown",
      },
      {
        label: "Sd2 Clarity",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "sd2_clarity",
        type: "dropdown",
      },
      {
        label: "Sd2 Carat",
        arrayAccessibleKey: "QuotationOtherDetails",
        key: "sd2_carat",
        type: "textbox",
      },
      //  ------------------- O ( Other Diamond ) ---------------
      {
        topLabel: "Other Diamond",
        label: "O Stone Type",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "o_stone_type",
        type: "dropdown",
      },
      {
        label: "O Stone Certificate",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "o_stone_certificate",
        type: "dropdown",
      },
      {
        label: "O Stone Name",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "o_stone_name",
        type: "dropdown",
      },
      {
        label: "O Shape",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "o_shape",
        type: "dropdown",
      },
      {
        label: "O Size",
        arrayAccessibleKey: "QuotationOtherDetails",
        key: "o_mm_size",
        type: "textbox",
      },
      {
        label: "O Piece",
        arrayAccessibleKey: "QuotationOtherDetails",
        key: "o_piece",
        type: "label",
      },
      {
        label: "O Color",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "o_color",
        type: "dropdown",
      },
      {
        label: "O Clarity",
        arrayAccessibleKey: "QuotationAttributeOptions",
        key: "o_clarity",
        type: "dropdown",
      },
      {
        label: "O Carat",
        arrayAccessibleKey: "QuotationOtherDetails",
        key: "o_carat",
        type: "textbox",
      },
    ],
  });
  return [state];
}
