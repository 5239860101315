import appConfig from "./appConfig";

const apiConfig = {
  publicURL: appConfig.host,
  baseURL: (
    appConfig.host +
    "/" +
    appConfig.prefix +
    "/" +
    appConfig.version +
    "/"
  ).replace(/([^:]\/)\/+/g, "$1"),

  // * ----- Auth Apis ----- *
  login: "login",

  // * ----- Change Password ----- *
  changePassword: "user-master/change-password",

  // * ----- User Managment  ----- *
  userMaster: "user-master",
  editUserMaster: "user-master/:id",
  toggleActiveDeactiveuser: "user-master/toggle-user-active/:id",

  // * ----- Customer  ----- *
  customerDetails: "customer-details",
  toggleActiveDeactivecustomer: "customer-details/toggle-customer-active/:id",
  deleteCustomer: "customer-details/:id",
  generateLogin: "customer-details/generate-login-id/:id",

  // * ----- Categories Managment  ----- *
  catgory: "category",
  editCategory: "category/:id",

  // * ----- Sub Categories Managment  ----- *
  subCatgory: "sub-category",
  editSubCatgory: "sub-category/:id",
  subCategoryAttributesId: "sub-category/attributes/:id",

  // * ----- Product Managment  ----- *
  products: "products",
  editProducts: "products/:id",
  productsBulk: "products/bulk",
  toggleActiveDeactiveProduct: "products/toggle-product-active/:id",
  // * ----- Catalog Managment  ----- *
  catalogMaster: "catalog-master",
  editCatalogMaster: "catalog-master/:id",
  toggleActiveDeactiveCatalogMaster: "catalog-master/toggle-catalog-active/:id",

  // * ----- Attributes  ----- *
  attributes: "attributes",
  editAttributes: "attributes/:id",

  // * ----- Options  ----- *
  options: "options",
  editOptions: "options/:id",

  // * ----- List APis  ----- *
  listCategory: "list/category",
  listOptions: "list/options",
  listSubCategory: "list/sub-category",
  listAttribute: "list/attributes",
  listCustomers: "list/customers",
  listProducts: "list/products",

  // * ----- User Permission  ----- *
  getuserPermission: "user-permission/:id",
  getUserNotPermission: "user-permission/:id/not",
  addUserPermission: "user-permission",
  editUserPermission: "user-permission/:id/toggle",
  deleteUserPermission: "user-permission/:id",

  // * ----- Customer Registration  ----- *
  customerRegistration: "registration",

  //  * -------------------------------------- Customer APIS ---------------------------------------*
  // * ----- Change Password ----- *
  changePasswordCustomer: "customer-details/change-password",

  // * ----- Change Profile ----- *
  customerDetailsProfile: "customer-details/profile",

  // * ----- Add To Quote ----- *
  addToQuote: "add-to-quote",
  addToQuoteCount: "add-to-quote/count",
  editToQuote: "add-to-quote/:id",

  // * ----- Quotation ----- *
  quotation: "quotation",
  findQuotation: "quotation/:id",
  toggleQuotationStatus: "quotation/status/:id",

  // * ----- Style Master ----- *
  styleMaster: "style-master/sub-category/:id",
  findStyleMaster: "style-master/:id",

  // * ----- Wish List ----- *
  wishlist: "wishList",
  editWishlist: "wishList/:id",
  // findStyleMaster: "style-master/:id",

  // * ----- Customer Catalog  ----- *
  customerCatalog: "catalog",
  findCatalog: "catalog/:id",

  // * ----- Other Details  ----- *
  otherDetaills: "other-details",
  productsImages: "products/product-images/:stock_id",

  // * -------- Dashboard --------- *
  dashboardTotal: "dashboard/total",
};

export default apiConfig;
