import React from "react";
import { Button, Spinner } from "reactstrap";

const ReactButton = ({ loader, children, onClick, ...rest }) => {
  return (
    <Button disabled={loader ? true : false} {...rest} onClick={onClick}>
      {loader ? (
        <Spinner size="sm" className="me-2">
          Loading...
        </Spinner>
      ) : null}
      {children}
    </Button>
  );
};

export default ReactButton;
