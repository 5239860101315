import React from "react";
import { Input } from "reactstrap";
import useQuotationFilter from "../../../hooks/useQuotationFilter";
import { HELPER } from "../../../services";

const AdminSingleQuotationPage = ({
  items,
  i,
  handleEditPrice,
  onChangePrice,
}) => {
  const [state] = useQuotationFilter();
  const generateQuotationAttributeOptions = (productFilter) => {
    let attrOptions = items.QuotationAttributeOptions.find(
      (e) => e.attribute_name == productFilter.key
    );
    if (attrOptions) {
      // Extract attribute name and option name
      const attributeName = productFilter.label;
      const optionName = attrOptions.option_name;

      // Construct and return the object with 'value' and 'label'
      return {
        label: attributeName,
        value: optionName,
      };
    }

    return null; // Return null if attrOptions is not found
  };

  const generateProductQuotationOtherDetails = (productFilter) => {
    let attrOptions = items?.QuotationOtherDetails?.find(
      (e) => e.detail_name === productFilter.key
    );

    // Check if attrOptions is not null or undefined
    if (attrOptions) {
      // Extract attribute name and option name
      const attributeName = productFilter.label;
      const optionName = attrOptions.detail_value;

      // Construct and return the object with 'value' and 'label'
      return {
        label: attributeName,
        value: optionName,
      };
    }

    return null; // Return null if attrOptions is not found
  };

  // Function to prepare product filter component based on arrayAccessibleKey
  const prepareProductFilterComponent = (productFilter) => {
    switch (productFilter.arrayAccessibleKey) {
      case "QuotationAttributeOptions":
        return generateQuotationAttributeOptions(productFilter);
      case "QuotationOtherDetails":
        return generateProductQuotationOtherDetails(productFilter);
      default:
        return null;
    }
  };

  // Iterate through product filters and prepare modified data
  let filteredDataDetails = state.sequenceProductFilter
    .map((productFilterOrder) => {
      return prepareProductFilterComponent(productFilterOrder);
    })
    .filter((e) => !HELPER.isEmpty(e));

  //  ---------------- mainProductLabel -----------------
  const mainProductLabel = [
    {
      label: "Metal Type",
      value: items?.Product?.metal_type,
    },
    {
      label: "Style",
      value: items?.Product?.style,
    },
    {
      label: "Setting Type",
      value: items?.Product?.setting_type,
    },
    {
      label: "Sub Setting",
      value: items?.Product?.sub_setting,
    },
    {
      label: "Prong Type",
      value: items?.Product?.prong_type,
    },
    {
      label: "Shank Type",
      value: items?.Product?.shank_type,
    },
    {
      label: "Band Type",
      value: items?.Product?.band_type,
    },
    {
      label: "Lock Type",
      value: items?.Product?.lock_type,
    },
    {
      label: "Bail Type",
      value: items?.Product?.bail_type,
    },
  ];

  return (
    <div key={i} className="product-box-div">
      <div className="product-attrbiute-options-heading">
        <span>Product Details</span>
      </div>
      <div className="quotation-product-div">
        <span className="opacity-span">Stock No : </span>
        <span>{items?.Product?.stock_id}</span>
      </div>
      <div className="quotation-product-div">
        <span className="opacity-span"> Product Name : </span>
        <span>{items?.Product?.name}</span>
      </div>
      {!HELPER.isEmpty(items?.Product?.style) && (
        <div className="quotation-product-div">
          <span className="opacity-span"> Style Name : </span>
          <span>{items?.Product?.style}</span>
        </div>
      )}
      <div className="quotation-product-div ">
        <span className="opacity-span">Qty : </span>
        <span>{items.qty}</span>
      </div>

      <div className="quotation-product-div mb-2 d-flex align-items-center ">
        <span className="opacity-span">Price : </span>
        <div className="d-flex align-items-center mx-2">
          <div className="wrap-div">
            <span className="item-text product-quotation-input-width">
              <Input
                type="number"
                className="product-quantity"
                placeholder="Price"
                defaultValue={items?.price}
                name="price"
                min="0"
                onChange={(e) => onChangePrice(i, e.target.value)}
              />
            </span>
          </div>
          <div className="mx-2">
            <button
              type="button"
              className="btn w-sm btn-outline-success waves-effect waves-light text-uppercase w-100"
              data-bs-dismiss="modal"
              onClick={() => handleEditPrice(items?.id)}
            >
              <span> Edit Price</span>
            </button>
          </div>
        </div>
      </div>
      <div className="product-quotation-grid">
        <div>
          <div className="product-attrbiute-options-heading">
            <span>Types</span>
          </div>
          <div className="wrapper-common-div ">
            {mainProductLabel.map(
              (option, key) =>
                option.value != "" && (
                  <div key={key} className="wrapper-div">
                    <span className="common-name-span">
                      {option.label.split("_").join(" ")}
                    </span>
                    :{" "}
                    <span className="common-name-span option-span">
                      {option.value.split("_").join(" ")}{" "}
                    </span>
                  </div>
                )
            )}
          </div>
        </div>
        <div>
          <div className="product-attrbiute-options-heading">
            <span>Product Details</span>
          </div>
          <div className="wrapper-common-div maintain-height-quotation">
            {filteredDataDetails.map((option, key) => (
              <div key={key} className="wrapper-div">
                <span className="common-name-span">
                  {option.label.split("_").join(" ")}
                </span>
                :{" "}
                <span className="common-name-span option-span">
                  {option.value.split("_").join(" ")}{" "}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSingleQuotationPage;
