import React, { useEffect, useState } from "react";
import { Button, Card, CardHeader, Container, Row } from "reactstrap";
import { apiConfig, appConfig } from "../../../configs";
import { API, HELPER } from "../../../services";
import moment from "moment";
import { useParams } from "react-router-dom";
import AdminSingleQuotationPage from "./AdminSingleQuotationPage";
import Swal from "sweetalert2";

const AdminProductQuotationViewPage = () => {
  // const navigate = useNavigate();
  const { id } = useParams();
  const [singleQuotationData, setSingleQuotationData] = useState([]);
  const [productFormData, setProductFormData] = useState([]);

  const getAddToQuoteData = () => {
    API.get(apiConfig.findQuotation.replace(":id", id))
      .then((res) => {
        setSingleQuotationData(res);

        setProductFormData(
          res?.QuotationProducts?.map((item) => ({
            id: item.id,
            price: item.price || 0,
          }))
        );
      })
      .catch(HELPER.showErrorToaster);
  };
  useEffect(() => {
    getAddToQuoteData();
  }, []);

  const customerInformations = [
    {
      label: "Customer Name",
      value: singleQuotationData?.CustomerDetail?.customer_name,
    },
    {
      label: "Customer Email",
      value: singleQuotationData?.CustomerDetail?.customer_email,
    },
    {
      label: "Mobile Number",
      value: singleQuotationData?.CustomerDetail?.mobile_number,
    },
    {
      label: "WhatsApp Number",
      value: singleQuotationData?.CustomerDetail?.whatsapp_number,
    },
  ];

  const onChangePrice = (index, value) => {
    setProductFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData[index].price = Number(value);
      return updatedFormData;
    });
  };
  const handleEditPrice = (dataId) => {
    const productDataPayload = productFormData.filter(
      (item) => item.id == dataId
    );
    let payload = {
      price: productDataPayload[0]?.price,
    };
    const apiUrl = apiConfig.findQuotation.replace(":id", dataId);
    API["put"](apiUrl, payload)
      .then((res) => {
        HELPER.toaster.success(res);
        getAddToQuoteData();
      })
      .catch(HELPER.showErrorToaster);
  };
  //  ---------------- onClickStatusChange ---------------------
  const onClickStatusChange = (id) => {
    Swal.fire({
      title: "Are You Sure",
      text: "You want to completed this status ?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "red",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        API.put(apiConfig.toggleQuotationStatus.replace(":id", id))
          .then((res) => {
            HELPER.toaster.success(res);
            getAddToQuoteData();
          })
          .catch(HELPER.showErrorToaster);
      }
    });
  };

  return (
    <React.Fragment>
      <div className="page-content main-parent-quotation-page-div ">
        <Container fluid className="mb-4 ">
          <Row>
            <Card id="employeeList" className="mb-0">
              <CardHeader className="border-0">
                <Row className="g-4 align-items-center text-center">
                  <div className="col-sm">
                    <div>
                      <h3 className="card-title mb-0 product-details-title">
                        Product Quotation
                      </h3>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <Container fluid>
                <Row className="main-wrap-row">
                  <div className="table-responsive margin-top-table table-border attributes-table-div">
                    <div className="main-wrapper-cards mb-4">
                      <div className="common-quotation-card">
                        <div className="d-flex justify-content-between align-items-center wrap-product-quotation-div">
                          <div className="date-and-status-div">
                            <div className="wrap-div">
                              <span className="label-text">
                                Quotation Date :{" "}
                              </span>
                              <span className="item-text">
                                {moment(
                                  singleQuotationData?.quotation_date
                                ).format(appConfig.displayDateAndTimeOnly)}
                              </span>
                            </div>

                            <div className="wrap-div">
                              <span className="label-text">Status : </span>
                              <span
                                className={`badge item-text
                            ${
                              (singleQuotationData.status == "pending" &&
                                "bg-danger") ||
                              (singleQuotationData.status == "completed" &&
                                "bg-success")
                            }`}
                                // className="item-text"
                              >
                                {singleQuotationData?.status}
                              </span>
                            </div>
                            <div className="wrap-div">
                              <span className="label-text">Total Price : </span>
                              <span className="item-text">
                                {HELPER.GetFormattedAmount(
                                  singleQuotationData?.totalPrice,
                                  0
                                )}
                              </span>
                            </div>
                          </div>
                          {singleQuotationData?.status !== "completed" && (
                            <div>
                              <Button
                                className="btn w-sm btn-success"
                                data-bs-dismiss="modal"
                                onClick={() =>
                                  onClickStatusChange(singleQuotationData.id)
                                }
                                disabled={
                                  singleQuotationData &&
                                  singleQuotationData?.QuotationProducts?.some(
                                    (item) =>
                                      item.price == 0 || item.price == null
                                  )
                                    ? true
                                    : false
                                }
                              >
                                Mark As Completed
                              </Button>
                            </div>
                          )}
                        </div>
                        <div>
                          <div className="wrap-quotation-cutomer-details-div px-3 mt-2">
                            <div className="wrapper-quotation-div">
                              {customerInformations.map(
                                (items, index) =>
                                  items.value != "" && (
                                    <div key={index}>
                                      <div className="wrapper-div">
                                        <span className="common-name-span">
                                          {items.label}:{" "}
                                        </span>
                                        <span className="common-name-span option-span">
                                          {items.value}
                                        </span>
                                      </div>
                                    </div>
                                  )
                              )}
                            </div>
                            <div className="wrapper-div mt-1">
                              <span className="common-name-span">
                                Customer Address :{" "}
                              </span>
                              <span className="common-name-span option-span">
                                {
                                  singleQuotationData?.CustomerDetail
                                    ?.customer_address
                                }
                              </span>
                            </div>
                          </div>
                          <div className="product-wrapper-main-div wrapper-admin-quotation-page">
                            {singleQuotationData?.QuotationProducts?.map(
                              (items, i) => (
                                <React.Fragment key={i}>
                                  <AdminSingleQuotationPage
                                    items={items}
                                    i={i}
                                    handleEditPrice={handleEditPrice}
                                    onChangePrice={onChangePrice}
                                  />
                                </React.Fragment>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>
              </Container>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AdminProductQuotationViewPage;
