//  ------------------ User Switch ( Active Deactive ) -------------------

export const isActiveDeactive = [
  { label: "Active", value: true },
  { label: "De Active", value: false },
];
export const quotationStatus = [
  { label: "Pending", value: "pending" },
  { label: "Completed", value: "completed" },
];
