import React, { useState, useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
} from "reactstrap";
import { store } from "../../slices";
import {
  authSlice,
  handleCounting,
  handleLogout,
  handleLogoutCustomer,
} from "../../slices/toolkit/auth";
import ChangePassword from "../../pages/ChangePassword/ChangePassword";
import { useDispatch, useSelector } from "react-redux";
import { CustomerAPI, HELPER } from "../../services";
import noimg from "../../assets/images/users/noimg.jpg";
import { ROUTES, apiConfig } from "../../configs";
import Swal from "sweetalert2";

const ProfileDropdown = () => {
  const auth = useSelector((state) => state.auth);
  const [isChangePass, setChangePass] = useState(false);
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const navigate = useNavigate();

  //   --------- Define Functions -----------------
  const PassToggle = useCallback(() => {
    setChangePass(false);
  }, [isChangePass]);

  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  const NavigateAddToQuotePage = () => {
    navigate(ROUTES.ADD_TO_QUOTE);
  };
  const NavigateWishListPage = () => {
    navigate(ROUTES.WISH_LIST);
  };
  const NavigateQuotationPage = () => {
    navigate(ROUTES.QUOTATION);
  };
  const NavigateProfilePage = () => {
    navigate(ROUTES.CUSTOMER_PROFILE);
  };
  //  ------------------------ Add To Quote Count API ( Only Count Disaply ) -----------------
  const getAddToQuoteDataCount = () => {
    CustomerAPI.get(apiConfig.addToQuoteCount)
      .then((res) => {
        store.dispatch(handleCounting(res));
      })
      .catch(HELPER.showErrorToaster);
  };

  if (HELPER.isEmpty(auth?.user)) {
    useEffect(() => {
      getAddToQuoteDataCount();
    }, []);
  }

  const onClickLogoutCustomer = () => {
    Swal.fire({
      title: "Are You Sure",
      text: "Are you sure you want to Logout ?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "red",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        store.dispatch(handleLogoutCustomer());
      }
    });
  };

  const onClickLogoutAdmin = () => {
    Swal.fire({
      title: "Are You Sure",
      text: "Are you sure you want to Logout ?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "red",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        store.dispatch(handleLogout());
      }
    });
  };

  return (
    <React.Fragment>
      {HELPER.isEmpty(auth?.user) && (
        <div className="d-flex">
          <div className="me-4">
            <div
              className="dropdown-item wishlist-hover-div"
              onClick={() => {
                NavigateWishListPage();
              }}
            >
              <span className="common-hovers-tooltip">Wish List</span>
              <i className=" ri-heart-line text-muted fs-16 align-middle me-1 header-icons"></i>
            </div>
          </div>
          <div className="me-4">
            <div
              className="dropdown-item position-relative wishlist-hover-div"
              onClick={() => {
                NavigateAddToQuotePage();
              }}
            >
              <span className="common-hovers-tooltip">Add To Quote</span>
              <div className="profile-cart-circle-div d-flex justify-content-center align-items-center">
                <span>{auth?.totalQuote}</span>
              </div>
              <i className="ri-shopping-bag-3-line text-muted fs-16 align-middle me-1 header-icons"></i>
            </div>
          </div>

          <div className="me-4">
            <div
              className="dropdown-item wishlist-hover-div"
              onClick={() => {
                NavigateQuotationPage();
              }}
            >
              <span className="common-hovers-tooltip">Quotation</span>
              <i className=" ri-file-list-2-line text-muted fs-16 align-middle me-1 header-icons"></i>
            </div>
          </div>
          <div className="me-3">
            <div
              className="dropdown-item wishlist-hover-div"
              onClick={() => {
                setChangePass(true);
              }}
            >
              <span className="common-hovers-tooltip">Change Password</span>
              <i className=" ri-lock-password-line text-muted align-middle me-1 header-icons"></i>
            </div>
          </div>
          <div className="me-3">
            <div
              className="dropdown-item wishlist-hover-div"
              onClick={() => {
                NavigateProfilePage();
              }}
            >
              <span className="common-hovers-tooltip">Profile</span>
              <i className=" mdi mdi-account-circle text-muted align-middle me-1 header-icons"></i>
            </div>
          </div>
          <div>
            <div
              className="dropdown-item wishlist-hover-div"
              onClick={() => {
                onClickLogoutCustomer();
              }}
            >
              <span className="common-hovers-tooltip">Logout</span>
              <i className=" mdi mdi-logout text-muted fs-16 align-middle me-1 header-icons"></i>
            </div>
          </div>
        </div>
      )}

      {!HELPER.isEmpty(auth?.user?.name) && (
        <Dropdown
          isOpen={isProfileDropdown}
          toggle={toggleProfileDropdown}
          className="ms-sm-3 header-item topbar-user"
        >
          <DropdownToggle tag="button" type="button" className="btn">
            <span className="d-flex align-items-center">
              <img
                className="rounded-circle header-profile-user"
                src={HELPER.getImageUrl(auth?.user?.profile)}
                alt="Header Avatar"
                onError={(e) => {
                  e.target.src = noimg;
                }}
              />
              <span className="text-start ms-xl-2">
                <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                  {auth?.user?.name}
                </span>
              </span>
            </span>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <h6 className="dropdown-header">
              Welcome{" "}
              {auth?.user?.name != null
                ? auth?.user?.name
                : auth?.customer?.customer_name}{" "}
              !
            </h6>
            {HELPER.isEmpty(auth?.user?.name) && (
              <DropdownItem className="p-0">
                <div
                  className="dropdown-item"
                  onClick={() => navigate(ROUTES.CUSTOMER_PROFILE)}
                >
                  <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle" data-key="t-logout">
                    Profile
                  </span>
                </div>
              </DropdownItem>
            )}

            <DropdownItem className="p-0">
              <div
                className="dropdown-item"
                onClick={() => {
                  setChangePass(true);
                }}
              >
                <i className="ri-lock-password-line text-muted fs-16 align-middle me-1"></i>
                <span className="align-middle">Change Password</span>
              </div>
            </DropdownItem>
            {!HELPER.isEmpty(auth?.user) && (
              <DropdownItem className="p-0">
                <Link
                  className="dropdown-item"
                  onClick={() => {
                    onClickLogoutAdmin();
                  }}
                >
                  <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle" data-key="t-logout">
                    Logout
                  </span>
                </Link>
              </DropdownItem>
            )}
          </DropdownMenu>
        </Dropdown>
      )}
      {/* Change Password Component */}
      {isChangePass && (
        <ChangePassword
          modal={isChangePass}
          setModal={setChangePass}
          toggle={PassToggle}
        />
      )}
    </React.Fragment>
  );
};

export default ProfileDropdown;
