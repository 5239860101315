/*------------------ Common Routes ------------------*/
export const HOME = "/";
export const LOGIN = "/admin/login";

export const DASHBOARD = "/dashboard";
export const EMPLOYEE = "/employee";
export const USERS = "/user";
export const WISH_LIST = "/wishlist";
export const CATEGORY = "/category";
export const PRODUCT = "/product";
export const PRODUCT_DETAILS = "/product-details";
export const ADD_TO_QUOTE = "/add-to-quote";
export const ADMIN_QUOTATION = "/quotation";
export const ADMIN_QUOTATION_VIEW = "/quotation-view";
export const OPTIONS = "/option";
export const ATTRIBUTE = "/attribute";
export const SUB_CATEGORY = "/sub-category";
export const USER_PERMISSION = "/user-permission/:id";
export const CATALOG_MASTER = "/catalog-master";

//  ------------- Customer Routes ------------
export const CUSTOMER = "/customer";
export const CUSTOMER_LOGIN = "/login";
export const CUSTOMER_PROFILE = "/customer-profile";
export const CUSTOMER_REGISTRATION = "/registration";
export const CUSTOMER_PRODUCT = "/customer-product";
export const CUSTOMER_PRODUCT_MASTER = "/product";
export const CUSTOMER_CATALOG = "/catalog";
export const QUOTATION = "/quotation";

export const filterRoute = (route) => {
  return route.substring(1);
};
