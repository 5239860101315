import React, { lazy } from "react";
import { ROUTES } from "../configs";
import { filterRoute } from "../configs/routes";
import { Navigate } from "react-router-dom";
import AdminProductQuotationViewPage from "../pages/Catalog/Product/AdminProductQuotationViewPage";

const Login = lazy(() => import("../pages/Authentication/Login"));
const LandingPage = lazy(() => import("../pages/Authentication/LandingPage"));
const DashboardEcommerce = lazy(() => import("../pages/DashboardEcommerce"));

// * ------ User Page ----- *
const UserPage = lazy(() => import("../pages/Catalog/UserDetails/UserPage"));

//  * ------ Customer Page ----- *
const CustomerPage = lazy(() =>
  import("../pages/Catalog/Customer/CustomerPage")
);
//  * ------ Category Page ----- *
const CategoryPage = lazy(() =>
  import("../pages/Catalog/Category/CategoryPage")
);

//  * ------ Sub Category Page ----- *
const SubCategoryPage = lazy(() =>
  import("../pages/Catalog/SubCategory/SubCategoryPage")
);

//  * ------ User Permission Page ----- *
const UserPermission = lazy(() =>
  import("../pages/Catalog/Permissions/UserPermission")
);

// * ------ Customer Login Page ----- *
const CustomerLogin = lazy(() =>
  import("../pages/Authentication/CustomerLogin")
);

// * ------ Customer Registration Page ----- *
const RegistrationPage = lazy(() =>
  import("../pages/Authentication/RegistrationPage")
);

// * ------ Customer Profile Page ----- *
const CustomerProfilePage = lazy(() =>
  import("../pages/Catalog/Customer/CustomerProfilePage")
);

// * ------ Product Page ----- *
const ProductPage = lazy(() => import("../pages/Catalog/Product/ProductPage"));

// * ------ Option Page ----- *
const OptionPage = lazy(() => import("../pages/Catalog/Option/OptionPage"));

// * ------ Attributes Page ----- *
const AttributesPage = lazy(() =>
  import("../pages/Catalog/Attributes/AttributesPage")
);
// * ------ Catalog Master Page ----- *
const CatalogMasterPage = lazy(() =>
  import("../pages/Catalog/CatalogMaster/CatalogMasterPage")
);

// * ------ Customer Product Details Page ----- *
const CustomerProductDetailsPage = lazy(() =>
  import("../pages/Catalog/Product/CustomerProductDetails")
);

// * ------ Customer Add To Quote Page ----- *
const CustomerAddToQuotePage = lazy(() =>
  import("../pages/Catalog/Product/CustomerAddToQuotePage")
);

// * ------ Customer Quotation Page ----- *
const CustomerQuotationPage = lazy(() =>
  import("../pages/Catalog/Product/CustomerQuotationPage")
);
// * ------ Customer WishList Page ----- *
const CustomerWishListPage = lazy(() =>
  import("../pages/Catalog/Product/CustomerWishListPage")
);
// * ------ Customer Catalog Page ----- *
const CustomerCatalogPage = lazy(() =>
  import("../pages/Catalog/Product/CustomerCatalogPage")
);
// * ------ Customer Product Master Page ----- *
const CustomerProductMasterPage = lazy(() =>
  import("../pages/Catalog/Product/CustomerProductMasterPage")
);
// * ------ Admin Product Quotation Page ----- *
const AdminProductQuotationPage = lazy(() =>
  import("../pages/Catalog/Product/AdminProductQuotationPage")
);

const authProtectedRoutes = [
  { path: filterRoute(ROUTES.DASHBOARD), component: <DashboardEcommerce /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to={filterRoute(ROUTES.DASHBOARD)} />,
  },
  { path: "*", component: <Navigate to={filterRoute(ROUTES.DASHBOARD)} /> },

  //  * ------ User Page ----- *
  {
    path: `${filterRoute(ROUTES.USERS)}`,
    component: <UserPage />,
  },
  // * ------ Customer Page ----- *
  {
    path: `${filterRoute(ROUTES.CUSTOMER)}`,
    component: <CustomerPage />,
  },

  // * ------ Category Page ----- *
  {
    path: filterRoute(ROUTES.CATEGORY),
    component: <CategoryPage />,
  },

  // * ------ Sub Category Page ----- *
  {
    path: filterRoute(ROUTES.SUB_CATEGORY),
    component: <SubCategoryPage />,
  },

  //  * ------ User Permission Page ----- *
  {
    path: filterRoute(ROUTES.USER_PERMISSION),
    component: <UserPermission />,
  },

  //  * ------ Product Page ----- *
  {
    path: filterRoute(ROUTES.PRODUCT),
    component: <ProductPage />,
  },
  //  * ------ Options Page ----- *
  {
    path: filterRoute(ROUTES.OPTIONS),
    component: <OptionPage />,
  },

  //  * ------ Attributes Page ----- *
  {
    path: filterRoute(ROUTES.ATTRIBUTE),
    component: <AttributesPage />,
  },
  //  * ------ Catalog Master Page ----- *
  {
    path: filterRoute(ROUTES.CATALOG_MASTER),
    component: <CatalogMasterPage />,
  },
  //  * ------ Product Quotation Page ----- *
  {
    path: filterRoute(ROUTES.ADMIN_QUOTATION),
    component: <AdminProductQuotationPage />,
  },
  {
    path: `${filterRoute(ROUTES.ADMIN_QUOTATION_VIEW)}/:id`,
    component: <AdminProductQuotationViewPage />,
  },
];
const customerprotectedRoutes = [
  {
    path: filterRoute(ROUTES.CUSTOMER_CATALOG),
    component: <CustomerCatalogPage />,
  },
  {
    path: "/",
    exact: true,
    component: <Navigate to={filterRoute(ROUTES.CUSTOMER_CATALOG)} />,
  },
  {
    path: "*",
    component: <Navigate to={filterRoute(ROUTES.CUSTOMER_CATALOG)} />,
  },

  //  * ------- Customer Page ----------- *
  {
    path: `${filterRoute(ROUTES.CUSTOMER)}`,
    component: <CustomerPage />,
  },

  //  * ------- Customer Profile Page ----------- *
  {
    path: `${filterRoute(ROUTES.CUSTOMER_PROFILE)}`,
    component: <CustomerProfilePage />,
  },

  //  * ------ Customer Product Details Page ----- *
  {
    path: filterRoute(ROUTES.PRODUCT_DETAILS),
    component: <CustomerProductDetailsPage />,
  },
  {
    path: `${filterRoute(ROUTES.PRODUCT_DETAILS)}/:id`,
    component: <CustomerProductDetailsPage />,
  },

  //  * ------Customer Add To Quote Page ----- *
  {
    path: filterRoute(ROUTES.ADD_TO_QUOTE),
    component: <CustomerAddToQuotePage />,
  },

  //  * ------Customer Quotation Page ----- *
  {
    path: filterRoute(ROUTES.QUOTATION),
    component: <CustomerQuotationPage />,
  },

  //  * ------ Customer WishList Page ----- *
  {
    path: filterRoute(ROUTES.WISH_LIST),
    component: <CustomerWishListPage />,
  },

  //  * ------ Customer Catalog Page ----- *
  {
    path: filterRoute(ROUTES.CUSTOMER_CATALOG),
    component: <CustomerCatalogPage />,
  },
  //  * ------ Customer Product Master Page ----- *
  {
    path: filterRoute(ROUTES.CUSTOMER_PRODUCT_MASTER),
    component: <CustomerProductMasterPage />,
  },

  {
    path: `${filterRoute(ROUTES.CUSTOMER_PRODUCT_MASTER)}/:id`,
    component: <CustomerProductMasterPage />,
  },
];

const publicRoutes = [
  { path: filterRoute(ROUTES.HOME), component: <LandingPage /> },
  { path: filterRoute(ROUTES.LOGIN), component: <Login /> },
  { path: filterRoute(ROUTES.CUSTOMER_LOGIN), component: <CustomerLogin /> },
  {
    path: filterRoute(ROUTES.CUSTOMER_REGISTRATION),
    component: <RegistrationPage />,
  },
];

export { authProtectedRoutes, publicRoutes, customerprotectedRoutes };
