import React from "react";
import ReactSelect from "../../Components/Common/ReactSelect";
import GlobalFilter from "../../Components/Ui/GlobalFilter";
import { Col, Collapse, Row, UncontrolledTooltip } from "reactstrap";

const ProductFiltersPage = ({ state }) => {
  return (
    <div className="mt-4 px-1">
      <label className="product-filter-label">Apply Filter :</label>
      <div className="px-2 mt-1">
        <GlobalFilter
          // searchValue={state.searchTxt}
          // setSearchValue={(searchTxt) => {
          //   changeState("searchTxt", searchTxt);
          // }}
          // handleSearchEnter={handleSearchEnter}
          SearchPlaceholder={"Search for Product Name"}
        >
          <Row>
            <Col xl={4} className="mt-2">
              <div className="employee-leave-select">
                <ReactSelect
                  // key={resetReactSelect ? "rerender" : "no-rerender"}
                  placeholder=" Sub Category Name"
                  options={[]}
                  // onChange={(e) => {
                  //   changeState("sub_category_id", e?.target.value || "");
                  // }}
                  name="sub_category_id"
                />
              </div>
            </Col>
            <Col sm={3}>
              <div className="employee-leave-select">
                <ReactSelect
                  // key={resetReactSelect ? "rerender" : "no-rerender"}
                  // value={state?.is_active || ""}
                  placeholder="Select Is Active"
                  options={[]}
                  // onChange={(e) => {
                  //   changeState("is_active", e?.target.value);
                  // }}
                  name="is_active"
                />
              </div>
            </Col>
            <Col sm={3}>
              <div className="employee-leave-select">
                <ReactSelect
                  // key={resetReactSelect ? "rerender" : "no-rerender"}
                  // value={state?.is_active || ""}
                  placeholder="Select Style Master"
                  options={[]}
                  // onChange={(e) => {
                  //   changeState("is_active", e?.target.value);
                  // }}
                  name="is_active"
                />
              </div>
            </Col>
            <Col sm={3}>
              <div className="employee-leave-select">
                <ReactSelect
                  // key={resetReactSelect ? "rerender" : "no-rerender"}
                  // value={state?.is_active || ""}
                  placeholder="Select Options"
                  options={[]}
                  // onChange={(e) => {
                  //   changeState("is_active", e?.target.value);
                  // }}
                  name="is_active"
                />
              </div>
            </Col>
            <div>
              <button
                type="button"
                className="btn btn-success w-50"
                // onClick={() => paginate(false, true)}
              >
                <i className="ri-search-line me-2 align-bottom"></i>
                Search
              </button>
              <button
                id="tooltipTop16"
                type="button"
                className="btn btn-danger  mx-2 w-10"
                // onClick={() => {
                //   setResetReactSelect(!resetReactSelect);
                //   paginate(true);
                // }}
              >
                <i className="ri-restart-line align-bottom"></i>
                <UncontrolledTooltip placement="top" target="tooltipTop16">
                  Reset
                </UncontrolledTooltip>
              </button>
            </div>
          </Row>
        </GlobalFilter>
      </div>
    </div>
  );
};

export default ProductFiltersPage;
